<template>
  <div
    :style="`transform: translateX(${moveX}px)`"
    class="swiper"
    ref="swipper"
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
  >
    <slot v-if="isShow"></slot>
  </div>
</template>

<script>
import { ref, computed } from "vue";
export default {
  props: {
    changePage: Function,
    currentId: String,
    list: Array,
    afterChangePage: Function,
  },
  setup(props) {
    const startX = ref(0);
    const moveX = ref(0);
    const swipper = ref(null);
    const isShow = ref(true);

    const currentIndex = computed(() => {
      let index;
      for (let i = 0; i < props.list.length; i++) {
        if (props.list[i].id === Number(props.currentId)) {
          index = i;
        }
      }
      return index;
    });

    const nextKey = computed(() => {
      if (currentIndex.value + 1 < props.list.length) {
        return props.list[currentIndex.value + 1]?.id;
      }
      return null;
    });

    const preKey = computed(() => {
      if (currentIndex.value - 1 >= 0) {
        return props.list[currentIndex.value - 1]?.id;
      }
      return null;
    });

    const touchStart = (e) => {
      // console.log("touchStart", e.touches[0].pageX);
      startX.value = e.touches[0].pageX;
    };

    const touchMove = (e) => {
      const currentX = e.touches[0].pageX;
      const distance = currentX - startX.value;

      if (Math.abs(distance) < 35) return;

      const isNext = distance < 0;
      if (!isNext && !preKey.value) return;
      if (isNext && !nextKey.value) return;

      if (100 > Math.abs(distance)) {
        moveX.value = distance;
      }
    };

    const touchEnd = () => {
      if (Math.abs(moveX.value) > 80) {
        moveX.value = Math.sign(moveX.value) * swipper.value.clientWidth;
        isShow.value = false;

        const isNext = moveX.value < 0;
        if (isNext) {
          props.changePage(nextKey.value, isNext);
          props.afterChangePage && props.afterChangePage(nextKey.value, isNext);
        } else {
          props.changePage(preKey.value, isNext);
          props.afterChangePage && props.afterChangePage(preKey.value, isNext);
        }

        setTimeout(() => {
          moveX.value = 0;
          isShow.value = true;
        }, 500);
      } else {
        moveX.value = 0;
      }
    };

    return {
      touchStart,
      touchMove,
      touchEnd,
      moveX,
      swipper,
      nextKey,
      preKey,
      isShow,
    };
  },
};
</script>

<style scoped lang="scss">
.swiper {
  position: relative;
  overflow: hidden;
  // width: 100%;

  .left {
    position: absolute;
    top: 0;
    right: -50px;
    height: 100%;
    width: 50px;
  }
}
</style>
